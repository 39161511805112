import React from "react";
import PageHeading from "@common/PageHeading/PageHeading";
import styled from "styled-components";
import { MOBILE_MIN_WIDTH, TABLET_MAX_WIDTH } from "@constants";
import ProcessItem from "./ProcessListItem/ProcessListItem";

const ProcessListSection = ({
    ProcessItemsInfo,
    isMobile,
    heading,
    itemsQuantity,
}) => (
    <div>
        <PageHeading heading={heading} />
        <ProcessItemsContainer className="process-item__container">
            {ProcessItemsInfo.map((processItemInfo, index) => {
                const imageIndex = isMobile ? 1 : 0;
                const currentImage = Array.isArray(processItemInfo.image)
                    ? processItemInfo.image[imageIndex]
                    : processItemInfo.image;
                return (
                    <ProcessItem
                        heading={processItemInfo.heading}
                        description={processItemInfo.description}
                        image={currentImage}
                        isShifted={processItemInfo.isShifted}
                        number={index}
                        itemsQuantity={itemsQuantity}
                        isGif={processItemInfo.isGif}
                        key={`${index}+${currentImage}`}
                    />
                );
            })}
        </ProcessItemsContainer>
    </div>
);

const ProcessItemsContainer = styled.div`
    margin: 80px 0 160px;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-bottom: 108px;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin: 40px 0 80px;
    }
`;

export default ProcessListSection;
