import React, { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-grid-system";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import useWindowDimensions from "@hooks/useWindowDimensions";
import GifAnimation from "@common/GifAnimation/GifAnimation";
import {
    MOBILE_MIN_WIDTH,
    TABLET_MAX_WIDTH,
    PROCESS_SECTION_BORDER_HEIGHT,
} from "@constants";

const ProcessListItem = ({
    heading,
    description,
    image,
    isGif,
    number,
    itemsQuantity,
    isShifted,
}) => {
    const GIFImageRef = useRef(null);
    const WrapperRef = useRef(null);
    const [isSectionVisible, setIsSectionVisible] = useState(false);
    const [isAnimationEnded, setIsAnimationEnded] = useState(false);
    const { isDesktop, isMobile } = useWindowDimensions();

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                setIsSectionVisible(entries[0].isIntersecting);
            },
            { root: null, threshold: [0], rootMargin: "108px" }
        );

        observer.observe(WrapperRef.current);

        return () => observer.disconnect();
    }, []);

    const calculateFrame = (ration, frame) => {
        if (isAnimationEnded) return frame - 1;
        return frame + 1;
    };

    return (
        <div ref={WrapperRef}>
            <Container
                fluid
                style={{ marginBottom: !isMobile ? "80px" : "40px" }}
            >
                <Row>
                    <Col lg={6}>
                        <ImageContainer isShifted={isShifted}>
                            {isDesktop && (
                                <BorderContainer
                                    number={number}
                                    className={`process-item__border-container process-item__border-container${
                                        number + 1
                                    }`}
                                >
                                    <BorderItem
                                        width={
                                            PROCESS_SECTION_BORDER_HEIGHT /
                                            itemsQuantity
                                        }
                                        offsetY={
                                            (PROCESS_SECTION_BORDER_HEIGHT /
                                                itemsQuantity) *
                                            number
                                        }
                                    />
                                </BorderContainer>
                            )}
                            {!isDesktop && !isMobile && isGif && (
                                <GifAnimation
                                    gifElementRef={GIFImageRef}
                                    gifAnimation={image}
                                    calculateFrame={(count, frame) =>
                                        calculateFrame(count, frame)
                                    }
                                    onAnimationEnded={isAnimatedForward =>
                                        setIsAnimationEnded(isAnimatedForward)
                                    }
                                    isPlay={isSectionVisible}
                                >
                                    <div className="app" ref={GIFImageRef} />
                                </GifAnimation>
                            )}
                            <ReactSVG
                                src={image}
                                alt={`process ${number} image`}
                                className={`process-item__image process-item__image${
                                    number + 1
                                }`}
                            />
                        </ImageContainer>
                    </Col>
                    <Col lg={6}>
                        <TextContainer>
                            <h3>{heading}</h3>
                            <p>{description}</p>
                        </TextContainer>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const ImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & .process-item__image {
        display: flex;
        min-height: 350px;
        align-items: center;

        @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            align-items: flex-start;
            margin-top: ${props => (props.isShifted ? "35px" : null)};
            margin-bottom: ${props => (props.isShifted ? "-35px" : null)};
        }

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            align-items: center;
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        justify-content: center;
        margin-bottom: 16px;
    }

    @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
        margin-bottom: 0;
        justify-content: flex-start;

        & .process-item__image {
            min-height: auto;
        }

        & div {
            display: flex;
            justify-content: center;
        }
    }
`;

const BorderContainer = styled.div`
    height: 350px;
    width: 2px;
    background: #4a2b43;
    margin-right: 72px;
`;

const BorderItem = styled.div`
    background: #c4b180 !important;
    height: ${props => props.width}px;
    width: 2px;
    margin-top: ${props => props.offsetY}px;
`;

const TextContainer = styled.div`
    margin-left: 40px;

    & p {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        color: var(--main-font-color);
        margin: 0;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        margin-left: -15px;
    }

    & h3 {
        opacity: 0.8;

        @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
            line-height: normal;
        }
    }
`;

export default ProcessListItem;
